import AuthDialogProvider from '@common/components/auth-dialog/AuthDialogProvider';
import Sidebar from '@common/components/Sidebar';
import SplashScreen from '@common/components/SplashScreen';
import { useAppDispatch, useAppSelector } from '@common/hooks/store';
import { isDomainZone } from '@common/utils/lang';
import config from '@config';
import AdmiralGameIframe from '@features/admiral/components/games/AdmiralGameIframe';
import CheckerIosVersion from '@features/app/CheckerIosVersion';
import { selectAppPlatform } from '@features/app/slice/appSlice';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import { openPersonalPopup } from '@features/banners/slice/personalPopupSlice';

import MoneyGate from '@features/deposit/screens/money-gate/MoneyGate';
import FreespinCampaignView from '@features/freespin/components/FreespinCampaignView';
import FreespinCampaigns from '@features/freespin/FreespinCampaigns';
import GiftBoxes from '@features/giftbox/GiftBoxes';
import MyBonuses from '@features/my-bonuses/MyBonuses';
import MyLimits from '@features/my-limits/MyLimits';
import SoftSwissResults from '@features/sport-games/components/SoftSwissResults';
import { surveyApi } from '@features/survey/api/surveyApi';
import WithdrawNotification from '@features/withdrawal/components/WithdrawNotification';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

const LoginScreen = lazy(() => import( '@common/layouts/login-screen/LoginScreen'));
const MainLayout = lazy(() => import( '@common/layouts/MainLayout'));
const AccountHistory = lazy(() => import( '@features/account-history/AccountHistory'));
const Addiction = lazy(() => import( '@features/addiction/Addiction'));
const Admiral = lazy(() => import( '@features/admiral/Admiral'));
const SignIn = lazy(() => import( '@features/auth/SignIn'));
const Basket = lazy(() => import( '@features/basket/Basket'));
const BonusesRoutes = lazy(() => import( '@features/bonuses/BonusesRoutes'));
const Campaigns = lazy(() => import( '@features/campaigns/Campaigns'));
const ChampionshipsRoutes = lazy(() => import( '@features/championships/ChampionshipsRoutes'));
const Cyberzone = lazy(() => import( '@features/cyberzone/Cyberzone'));
const Deposit = lazy(() => import( '@features/deposit/Deposit'));
const EventSearchRoutes = lazy(() => import( '@features/event-search/EventSearchRoutes'));
const Event = lazy(() => import( '@features/event/Event'));
const EventsResults = lazy(() => import( '@features/events-results/EventsResults'));
const ExactScore = lazy(() => import( '@features/exact-score/ExactScore'));
const Feedback = lazy(() => import( '@features/feedback/Feedback'));
const ForgotPassword = lazy(() => import( '@features/forgot-password/ForgotPassword'));
const Home = lazy(() => import( '@features/home/Home'));
const Keno = lazy(() => import( '@features/keno/Keno'));
const Live = lazy(() => import( '@features/live/Live'));
const MessageCEO = lazy(() => import( '@features/message-ceo/MessageCEO'));
const Messages = lazy(() => import( '@features/messages/Messages'));
const NewsRoutes = lazy(() => import( '@features/news/NewsRoutes'));
const Regulations = lazy(() => import( '@features/regulations/Regulations'));
const Schedule = lazy(() => import( '@features/schedule/Schedule'));
const SearchTicket = lazy(() => import( '@features/search-ticket/SearchTicket'));
const Settings = lazy(() => import( '@features/settings/Settings'));
const SignUp = lazy(() => import( '@features/sign-up/SignUp'));
const SmartsoftGaming = lazy(() => import( '@features/smartsoft-gaming/SmartsoftGaming'));
const SportGames = lazy(() => import( '@features/sport-games/SportGames'));
const Stream = lazy(() => import( '@features/stream/Stream'));
const Forecast = lazy(() => import( '@features/super-express/forecast/Forecast'));
const SuperExpress = lazy(() => import( '@features/super-express/regular/SuperExpress'));
const Survey = lazy(() => import( '@features/survey/Survey'));
const TopGames = lazy(() => import( '@features/top-games/TopGames'));
const UserBetsRoutes = lazy(() => import( '@features/user-bets/UserBetsRoutes'));
const Profile = lazy(() => import( '@features/user/profile/Profile'));
const Verification = lazy(() => import( '@features/verification/Verification'));
const VerificationLayout = lazy(() => import( '@features/verification/VerificationLayout'));
const Wallets = lazy(() => import( '@features/wallets/Wallets'));
const Withdrawal = lazy(() => import( '@features/withdrawal/Withdrawal'));
const SurveyPopup = lazy(() => import( '@features/survey/popup/SurveyPopup'));
const Sportradar = lazy(() => import( '@features/sportradar/Sportradar'));
const AccountDeactivation = lazy(() => import( '@features/account-deactivation/AccountDeactivation'));

export default function AppRoutes() {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(userLoggedIn);
  const platform = useAppSelector(selectAppPlatform);

  useEffect(() => {
    if (loggedIn) {
      dispatch(openPersonalPopup('afterLogin'));
      dispatch(openPersonalPopup('user'));
      dispatch(surveyApi.endpoints.fetchSurvey.initiate());
    }
  }, [ dispatch, loggedIn ]);

  return (
    <AuthDialogProvider>
      <Suspense fallback={<SplashScreen/>}>
        <Routes>
          <Route element={<LoginScreen/>}>
            <Route path="/sign-in" element={<SignIn/>}/>
            <Route path="/sign-up" element={<SignUp/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
          </Route>
          <Route path="/" element={<MainLayout/>}>
            <Route index element={<Home/>}/>
            <Route path="/news/*" element={<NewsRoutes/>}/>
            <Route path="/bonus-previews/*" element={<BonusesRoutes/>}/>
            <Route path="/live/*" element={<Live/>}/>
            <Route path="/top-games" element={<TopGames/>}/>
            <Route path="/championships/*" element={<ChampionshipsRoutes/>}/>
            <Route path="/event/:id" element={<Event/>}/>
            <Route path="/my-bets/*" element={<UserBetsRoutes/>}/>
            <Route path="/regulations" element={<Regulations/>}/>
            <Route path="/live-schedule" element={<Schedule/>}/>
            <Route path="/event-search/*" element={<EventSearchRoutes/>}/>
            <Route path="/message-ceo" element={<MessageCEO/>}/>
            <Route path="/feedback" element={<Feedback/>}/>
            <Route path="/messages/*" element={<Messages/>}/>
            <Route path="/results" element={<EventsResults/>}/>
            <Route path="/settings" element={<Settings/>}/>
            <Route path="/super-express" element={<SuperExpress/>}/>
            <Route path="/super-express/mma" element={<Forecast/>}/>
            <Route path="/profile/*" element={<Profile/>}/>
            <Route path="/search-ticket" element={<SearchTicket/>}/>
            <Route path="/exact-score" element={<ExactScore/>}/>
            <Route path="/wallets" element={<Wallets/>}/>
            <Route path="/account-history" element={<AccountHistory/>}/>
            <Route path="/withdrawal" element={<Withdrawal/>}/>
            <Route path="/deposit" element={<Deposit/>}/>
            <Route path="/deposit/money-gate/:id" element={<MoneyGate/>}/>

            <Route path="/stream/*" element={<Stream/>}/>
            <Route path="/addiction" element={<Addiction/>}/>

            <Route path="/admiral" element={<Admiral/>}/>
            <Route path="/admiral/campaigns" element={<FreespinCampaigns/>}/>
            <Route path="/admiral/campaigns/:id/:type" element={<FreespinCampaignView/>}/>
            <Route path="/my-bonuses" element={<MyBonuses/>}/>
            <Route path="/my-limits" element={<MyLimits/>}/>

            <Route path="/campaigns" element={<Campaigns/>}/>
            <Route path="/survey" element={<Survey/>}/>
            <Route path="/keno-games" element={<Keno/>}/>
            <Route path="/smartsoft-gaming" element={<SmartsoftGaming/>}/>
            <Route path="/sport-games" element={<SportGames/>}/>
            <Route path="/sport-games/sportsbook" element={<SoftSwissResults/>}/>
            <Route path="/table-tennis" element={<Sportradar url={config.sportradar.iframe['/table-tennis']}/>}/>
            {(isDomainZone('tj') || isDomainZone('ru') || isDomainZone('uz')) && (
              <Route path="/online-football" element={<Sportradar
                url={config.sportradar.iframe['/online-football']}/>}/>
            )}
            {(isDomainZone('tj') || isDomainZone('ru') || isDomainZone('uz')) && (
              <Route path="/table-tennis-squares" element={<Sportradar
                url={config.sportradar.iframe['/table-tennis-squares']}/>}/>
            )}
            <Route path="/account-deactivation" element={<AccountDeactivation/>}/>
          </Route>
          <Route path="/admiral/game/:game_id" element={<AdmiralGameIframe/>}/>
          {isDomainZone('ua') && <Route path="/cyberzone" element={<Cyberzone/>}/>}
          <Route path="/verification" element={<VerificationLayout/>}>
            <Route index element={<Verification/>}/>
          </Route>
          <Route path="*" element={<MainLayout/>}>
            <Route path="*" element={<Home/>}/>
          </Route>
        </Routes>
        <Basket/>
        {loggedIn && <SurveyPopup/>}
        {loggedIn && <GiftBoxes/>}
        {platform === 'pmi' && <CheckerIosVersion/>}
        {(loggedIn && isDomainZone('tj')) && <WithdrawNotification pageName="deposit_notification"/>}
      </Suspense>
      <Sidebar/>
    </AuthDialogProvider>
  );
}
