import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import { getUnixTime } from 'date-fns';

export const cdnApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPage: build.query<any, { lang: string, pageName: string }>({
      query: (params) => ({
        url: `${config.cdn}/pages/${params.lang}/${params.pageName}`,
        params: { v: getUnixTime(Date.now()) },
        responseHandler: (response) => response.text(),
      }),
      extraOptions: { useToken: false },
    }),
  }),
});

export const { useFetchPageQuery, useLazyFetchPageQuery } = cdnApi;
