export const USER_SESSION_TOKEN = 'token';
export const APP_SESSION = 'f55_session';
export const X_CODE = 'x-code';
export const APP_TRANSLATIONS = 'f55_translations';
export const APP_PLATFORM = 'platform';
export const APP_LANGUAGE = 'lang';
export const APP_ONE_CLICK_BET = 'f55_one_click_bet';
export const APP_LINE_ABBRS = 'f55_prematch_abbrs';
export const APP_LIVE_ABBRS = 'f55_live_abbrs';
export const APP_LINE_TEMPLATES = 'f55_prematch_templates';
export const APP_LIVE_TEMPLATES = 'f55_live_templates';
export const APP_LIVE_FAVORITES = 'f55_live_favorites';
export const APP_LIVE_GROUP_MODE = 'f55_live_group_mode';

export const APP_PREMATCH_FAVORITES = 'f55_prematch_favorites';
export const SPORT_GAMES_SESSION_TOKEN = 'f55_session';
export const APP_DOMAIN_ZONE = 'f55_domain_zone';
export const APP_BANNERS_POPUP = 'f55_banners_popup';
export const APP_ENABLE_DEV_CONFIG = 'f55_enable_dev_config';
export const APP_ADMIRAL_FAVORITES = 'f55_admiral_favorites';
export const APP_KEITARO_SUBID = 'f55_keitaro_subid';
export const APP_LCC = 'f55_lcc';
export const APP_PROMO_CODE = 'f55_promo_code';
export const APP_SURVEY_OPEN_TS = 'f55_survey_ts';
export const APP_FIREBASE_FCM = 'f55_firebase_fcm';
