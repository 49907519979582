import useBackdrop from '@common/components/backdrop/useBackdrop';
import FroalaTypography from '@common/components/FroalaTypography';
import TransitionZoom from '@common/components/Transitions/TransitionZoom';
import { getCountryCode, getLanguage } from '@common/utils/lang';
import { useFetchPageQuery } from '@features/cdn/cdnApi';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';

const WithdrawNotification = ({ pageName }: { pageName: string }) => {
  const backdrop = useBackdrop();
  const [ open, setOpen ] = useState(true);
  const { data, isLoading, isSuccess } = useFetchPageQuery({
    lang: getLanguage(),
    pageName: `${pageName}_${getCountryCode().toLowerCase()}.html`,
  });
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    backdrop(isLoading);
  }, [ backdrop, isLoading ]);

  if (isSuccess)
    return (
      <Dialog open={open}
              onClose={handleClose}
              maxWidth={'xs'}
              TransitionComponent={TransitionZoom}>
        <DialogContent onClick={handleClose}>
          <FroalaTypography dangerouslySetInnerHTML={{ __html: data }}/>
        </DialogContent>
      </Dialog>
    );
  else
    return null;
};

export default WithdrawNotification;