import useBackdrop from '@common/components/backdrop/useBackdrop';
import snackbarActions from '@common/components/snackbar/SnackbarActions';
import styled from '@emotion/styled';
import { useFetchGiftBoxesQuery, useOpenGiftBoxMutation } from '@features/giftbox/api/giftboxApi';
import { ImageList, ImageListItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import giftBoxImage  from '@assets/images/giftbox.webp';
import { FormattedMessage, useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import './firework.scss';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow: visible;
    background-image: none;
    background-color: rgba(12, 12, 12, 0.8);
  }
  
  .MuiDialogTitle-root {
    padding-bottom: 0;
  }
  
  .MuiIconButton-root {
    position: absolute;
    right: 8px;
    top: 8px;
    color: white;
    
    &:hover {
      color: red;
    }
  }
  
  .MuiDialogContent-root {
    padding: 10px;
    overflow: visible;
  }
`;
const StyledImageList = styled(ImageList)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-block: 0;
  overflow: visible;
  
  .MuiImageListItem-root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    width: 30%;
    padding-block: 10px;
    cursor: pointer;
    transition: background-color ease 300ms;
    overflow: visible;
    border-radius: 4px;
    border: 1px solid rgba(254, 233, 0, .8);
    background-color: rgba(18, 18, 18, .8);
    
    &:hover {
      background-color: rgba(254, 233, 0, .6);
    }
  }
`;
const StyledTypography = styled(Typography)`
  font-family: 'f55', Arial, serif;
  font-size: 18px;
  color: #fee900;
`;

export default function GiftBoxes() {
  const backdrop = useBackdrop();
  const confirm = useConfirm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const [ open, setOpen ] = useState<boolean>(true);
  const { data, isSuccess } = useFetchGiftBoxesQuery();
  const [ openGiftBox, openGiftBoxResult ] = useOpenGiftBoxMutation();
  const handleClick = (id: number) => () => {
    if (!openGiftBoxResult.isSuccess) {
      confirm({
        title: <FormattedMessage id="CONFIRM_GIFT_BOX"/>,
        buttonOrder: [ 'confirm', 'cancel' ],
        cancellationText: <FormattedMessage id="NO"/>,
        confirmationText: <FormattedMessage id="YES"/>,
        cancellationButtonProps: {
          color: 'error',
        },
        confirmationButtonProps: {
          color: 'warning',
        },
      }).then(() => {
        openGiftBox(id);
      });
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setOpen(true);
    }
  }, [ data, isSuccess ]);

  useEffect(() => {
    backdrop(openGiftBoxResult.isLoading);
  }, [ backdrop, openGiftBoxResult.isLoading ]);

  useEffect(() => {
    if (openGiftBoxResult.isError) {
      enqueueSnackbar(formatMessage({ id: 'error.request-failed' }), {
        action: snackbarActions(closeSnackbar),
        variant: 'error',
      });
    }
  }, [ closeSnackbar, enqueueSnackbar, formatMessage, openGiftBoxResult.isError ]);
  if (
    data &&
    Array.isArray(data.items) &&
    data.items.length
  ) {
    return (
      <StyledDialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          {data.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
        >
          <CloseIcon/>
        </IconButton>
        <DialogContent>
          <StyledImageList
            gap={10}
            cols={data.items.length > 3 ? 3: data.items.length}
          >
            {data.items.map((item) => (
              <ImageListItem
                key={item.id}
                onClick={handleClick(item.id)}
              >
                {(openGiftBoxResult.isSuccess && openGiftBoxResult.originalArgs?.toString() === item.id.toString()) ? (
                  <Fragment>
                    <StyledTypography>
                      {openGiftBoxResult.data.name}
                    </StyledTypography>
                    <div className="pyro fadeOut">
                      <div className="before"/>
                      <div className="after"/>
                    </div>
                  </Fragment>
                ) : (
                  <img
                    alt="Gift Box"
                    src={`${giftBoxImage}?w=164&h=164&fit=crop&auto=format`}
                    style={{ width: '90%', height: 'auto' }}
                    srcSet={`${giftBoxImage}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                  />
                )}
              </ImageListItem>
            ))}
          </StyledImageList>
        </DialogContent>
      </StyledDialog>
    );
  }
  return null;
}
