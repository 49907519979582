import { ICountry } from '@features/location/locationApi';

export interface IUserCurrency {
  id: number;
  no: number;
  code: string;
  title: string;
  balance: string;
  frozenAmount?: string;
}

export interface IUserStats {
  isVerified: number;
  basketRatio: IUserBasketRatio;
  liveInterest: number;
  adminVerified: number;
  prematchInterest: number;
  withdrawalRergion: number;
  withdrawalCashbox: number;
}

export interface IUserBonusCard {
  no: number;
  sum: number;
  title: string;
  amount: number;
  minSum: number;
  minOddRate: number;
  expressMinItems: number;
  expressMinOddRate: number;
}

export interface IUser {
  id: number;
  login: string;
  email: string;
  phone: string;
  stats: IUserStats;
  cityId: number;
  country: Pick<ICountry, 'id' | 'title' | 'code'>;
  currency: IUserCurrency;
  lastName: string;
  birthDay: string;
  firstName: string;
  phoneCode: number;
  passportNo: string;
  bonusCards: IUserBonusCard[];
  userAddress: string;
  maintenance: boolean;
  verifyStatus: IUserVerificationStatus;
  transferFunds: boolean;
  withdrawalPassword: boolean;
}

export interface IUserCredentials {
  login: string;
  password: string;
}

export interface IUserGeolocation extends Pick<
  IUser,
  'id' | 'login' | 'email' | 'firstName' | 'lastName' | 'birthDay' | 'phone' | 'phoneCode' | 'passportNo' | 'cityId' | 'country'
> {
  gpsCountry: Pick<ICountry, 'id' | 'title' | 'code'>;
  gpsUpdatedAt: number;
}

export enum IUserVerificationStatus {
  UNVERIFIED = 0,
  PENDING = 1,
  VERIFIED = 2,
  REJECTED = 3
}

export enum IUserBasketRatio {
  UP = 'up',
  ANY = 'any',
  NONE = 'none',
}


