import SplashScreen from '@common/components/SplashScreen';
import { APP_KEITARO_SUBID, APP_LCC, APP_PLATFORM, APP_PROMO_CODE } from '@common/constants';
import { useAppDispatch } from '@common/hooks/store';
import useVisibilityChange from '@common/hooks/useVisibilityChange';
import { isDomainZone } from '@common/utils/lang';
import '@common/utils/yupLocale';
import config from '@config';
import { useFetchTooltipsQuery } from '@features/app/api/appApi';
import AppLoader from '@features/app/AppLoader';
import { setAppPlatform } from '@features/app/slice/appSlice';
import { IAppState } from '@features/app/slice/appSlice.type';
import useCheckToken from '@features/auth/useCheckToken';
import { campaignsApi } from '@features/campaigns/api/campaignsApi';
import { getUnixTime } from 'date-fns';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

export default function App() {
  const googleAnalyticsIDs =
    isDomainZone('ua') ? config.googleAnalyticsIDs.ua.id :
      isDomainZone('ru') ? config.googleAnalyticsIDs.ru.id :
        isDomainZone('uz') ? config.googleAnalyticsIDs.uz.id :
          isDomainZone('org') ? config.googleAnalyticsIDs.org.id :
            isDomainZone('net') ? config.googleAnalyticsIDs.net.id :
          config.googleAnalyticsIDs.tj.id;

  ReactGA.initialize(googleAnalyticsIDs);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ searchParams ] = useSearchParams();
  const isChecking = useCheckToken();
  const tooltipsQuery = useFetchTooltipsQuery(undefined, {
    skip: isChecking,
  });
  useEffect(() => {
    const platform = searchParams.get(APP_PLATFORM) as IAppState['platform'];
    if (platform) {
      reactLocalStorage.set(APP_PLATFORM, platform);
      dispatch(setAppPlatform(platform));
    }
  }, [ dispatch, searchParams ]);

  useEffect(() => {
    const subid = searchParams.get('subid');
    if (subid) {
      const unixTime = getUnixTime(new Date());
      reactLocalStorage.setObject(APP_KEITARO_SUBID, {
        exp: unixTime + (60 * 30),
        code: subid,
      });
      dispatch(campaignsApi.endpoints.leadCampaigns.initiate(subid));
      navigate(location.pathname);
    }
    const lcc = searchParams.get('lcc');
    if (lcc) {
      const unixTime = getUnixTime(new Date());
      reactLocalStorage.setObject(APP_LCC, {
        exp: unixTime + (60 * 30),
        code: lcc,
      });
      dispatch(campaignsApi.endpoints.leadCampaigns.initiate(lcc));
      navigate(location.pathname);
    }
    const promoCode = searchParams.get('promo_code');
    if (promoCode) {
      const unixTime = getUnixTime(new Date());
      reactLocalStorage.setObject(APP_PROMO_CODE, {
        exp: unixTime + (60 * 30),
        code: promoCode,
      });
      navigate(location.pathname);
    }
  }, [ dispatch, location.pathname, navigate, searchParams ]);
  const isForeground = useVisibilityChange();
  useEffect( () => {
    // setupNotifications()
    // setupNotifications().then((message) => {
    //   console.log(message);
    //   console.log("App tsx firebase");
    //
    //   if (isForeground) {
    //     // App is in the foreground, show toast notification
    //     toastNotification({
    //       title: "test",
    //       description: "body",
    //       status: "info",
    //     });
    //   } else {
    //     // App is in the background, show native notification
    //     sendNativeNotification({
    //       title: "test",
    //       body: "body",
    //     });
    //   }
    // }).catch((e) => console.log(e));
  }, []);
  if (
    isChecking ||
    tooltipsQuery.isUninitialized ||
    tooltipsQuery.isLoading
  ) {
    return <SplashScreen/>;
  }
  return <AppLoader/>;
}
