import { USER_SESSION_TOKEN } from '@common/constants';
import { useAppSelector } from '@common/hooks/store';
import styled from '@emotion/styled';
import { userLoggedIn } from '@features/auth/slice/authSlice';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

const StyledIframeBox = styled(Box)`
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const SoftSwissResults = () => {
  const gameSrc = 'https://sports-dev.55winers.com/ru/live';
  const authToken = reactLocalStorage.get<string>(USER_SESSION_TOKEN);

  const navigate = useNavigate();
  const isAuth = useAppSelector(userLoggedIn);

  useEffect(() => {
    if (!isAuth) {
      navigate('/sign-in');
    }
  }, [ isAuth, navigate ]);

  useEffect(() => {
    const localWindow = window as any;

    // Функция получения токена
    const getAuthToken = async () => {
      // Возвращаем токен
      if (authToken) {
        return authToken;
      }
      return null;
    };

    const initSportsbook = () => {
      if (localWindow.Sportsbook) {
        try {
          // Инициализация Sportsbook с токеном
          localWindow.Sportsbook('init', {
            container: '#container',
            iframeSrc: gameSrc,
            getAuthToken, // передаем функцию получения токена
            tokenRefreshTimeBeforeExpire: 10, // обновление токена за 10 секунд до истечения
          });

          // Установка статуса аутентификации после инициализации
          if (authToken) {
            localWindow.Sportsbook('setAuthStatus', { authStatus: 'LOGGED_IN' });
            console.log('User is logged in');
          } else {
            localWindow.Sportsbook('setAuthStatus', { authStatus: 'NOT_LOGGED_IN' });
            console.log('User is not logged in');
          }
        } catch (error) {
          console.error('Error during Sportsbook initialization:', error);
        }
      } else {
        console.error('Sportsbook is not defined');
      }
    };

    // Проверка наличия Sportsbook и инициализация
    if (localWindow.Sportsbook) {
      if (!document.querySelector('#container iframe')) {
        initSportsbook();
      } else {
        console.warn('Sportsbook iframe already exists.');
      }
    } else {
      const interval = setInterval(() => {
        if (localWindow.Sportsbook) {
          clearInterval(interval);
          if (!document.querySelector('#container iframe')) {
            initSportsbook();
          } else {
            console.warn('Sportsbook iframe already exists.');
          }
        }
      }, 100);
    }

    // Очистка при размонтировании компонента
    return () => {
      if (localWindow.Sportsbook && typeof localWindow.Sportsbook === 'function') {
        try {
          localWindow.Sportsbook('kill');
          // console.log('Sportsbook destroyed');
        } catch (error) {
          console.error('Error during Sportsbook destruction:', error);
        }
      }
    };
  }, [authToken, gameSrc]); // Обновление при изменении токена или исходной ссылки

  useEffect(() => {
    if (window.location.pathname === '/sport-games/sportsbook') {
      const basket = document.querySelector('.mui__basket') as HTMLElement;
      const bottomNavigation = document.querySelector('.MuiBottomNavigation-root') as HTMLElement;
      if (basket) {
        basket.style.display = 'none';
      }
      if(bottomNavigation) {
        bottomNavigation.style.display = 'none';
      }
    }

    return () => {
      const basket = document.querySelector('.mui__basket') as HTMLElement;
      if (basket) {
        basket.style.display = 'block'; // Сбрасываем стиль
      }
      const bottomNavigation = document.querySelector('.MuiBottomNavigation-root') as HTMLElement;
      if(bottomNavigation) {
        bottomNavigation.style.display = 'flex';
      }
    };
  }, []);
  
  return <StyledIframeBox id="container" height="100vh"></StyledIframeBox>;
};

export default SoftSwissResults;
